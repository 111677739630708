import React, { useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import { device } from "../styles/device"
import OrderNowButton from "./orderNowButton"
import { useIntl } from "gatsby-plugin-react-intl"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

const ShopSection = () => {
    const intl = useIntl()

    const controls = useAnimation()
    const { ref, inView } = useInView({
        triggerOnce: true,
    })

    useEffect(() => {
        inView ? controls.start("animate") : controls.start("initial")
    }, [controls, inView])

    const transition = { duration: 1.4, ease: [0.6, -0.05, 0.01, 0.9] }

    const mask = {
        initial: {
            width: "100%",
        },
        animate: {
            width: 0,
        },
    }

    const data = useStaticQuery(graphql`
        query {
            allContentfulShopSection {
                nodes {
                    shopSectionImages {
                        gatsbyImageData
                    }
                    shopSectionDescription {
                        shopSectionDescription
                    }
                }
            }
        }
    `)
    const content =
        intl.locale === "ja" ? data.allContentfulShopSection.nodes[0] : data.allContentfulShopSection.nodes[1]

    const { shopSectionImages, shopSectionDescription } = content

    return (
        <div css={wrapper}>
            <div css={leftContainer}>
                <h2>Online Shop</h2>
                {shopSectionDescription && (
                    <p
                        dangerouslySetInnerHTML={{
                            __html: shopSectionDescription.shopSectionDescription.replace(/\n/g, "<br>"),
                        }}
                    />
                )}
                <div css={onlyPC}>
                    <OrderNowButton />
                </div>
            </div>
            <div css={rightContainer}>
                {shopSectionImages.length > 0 &&
                    shopSectionImages.map((asset, index) => (
                        <Link to="/shop" css={productLink} key={asset.gatsbyImageData.images.fallback.src}>
                            <GatsbyImage
                                image={getImage(asset)}
                                alt={`online shop image - ${index}`}
                                css={onlineShopImage}
                            />
                            <motion.div
                                ref={ref}
                                css={ShopMaskImage}
                                variants={mask}
                                initial="initial"
                                animate={controls}
                                transition={{ ...transition, delay: `0.${2 * index}` }}
                            ></motion.div>
                        </Link>
                    ))}
            </div>
            <div css={onlySP}>
                <OrderNowButton />
            </div>
        </div>
    )
}
export default ShopSection

const onlyPC = css`
    @media ${device.sp} {
        display: none;
    }
`
const onlySP = css`
    display: none;
    @media ${device.sp} {
        display: block;
        text-align: center;
    }
`
const wrapper = css`
    display: flex;
    height: min(80vmin, 550px);
    align-items: flex-end;
    margin-bottom: 20vh;
    @media ${device.tb} {
        height: 60vmin;
    }
    @media ${device.sp} {
        display: block;
        height: auto;
    }
`
const leftContainer = css`
    width: 25%;
    padding: 0 5% 5%;
    h2 {
        margin-bottom: 2rem;
    }
    p {
        margin-bottom: 5rem;
    }

    @media ${device.sp} {
        margin: 0 auto;
        width: 80%;
        padding: 0;

        h2 {
            text-align: center;
        }
    }
`
const rightContainer = css`
    width: 65%;
    height: 100%;
    display: flex;
    justify-content: space-between;

    /* @media ${device.tb} {
        a:nth-last-of-type(1) {
            display: none;
        }
    } */
    @media ${device.sp} {
        display: block;
        width: 90%;
        height: 60vmax;
        margin: 0 auto;
        overflow-x: hidden;
        overflow-y: scroll;
        margin-bottom: 8vmax;
    }
`
const productLink = css`
    width: 32%;
    position: relative;
    overflow: hidden;

    @media ${device.tb} {
        width: 33.33%;
    }
    @media ${device.sp} {
        width: 90%;
        margin: 0 auto;
    }
`
const onlineShopImage = css`
    width: 100%;
    height: 100%;
`
const ShopMaskImage = css`
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;

    @media ${device.sp} {
        display: none;
    }
`
